<script lang="ts" setup>
import JudgingPoint from '@/components/Battle/Prelims/JudgingPoint.vue'
import { IParticipant, PrelimsJudgment } from '@/types/battle'
import { computed, onMounted, ref } from 'vue'
import { mdiChevronDown, mdiChevronUp, mdiStarOutline } from '@mdi/js'
import { EventDB } from '@/enitites/event'
import { useImage } from '@/shared/composables/image'
import api from '@/api'

const props = defineProps<{
  participant: IParticipant
  battleId: number
  canEdit: boolean
  canSetGone: boolean
  isHighlighted: boolean
  isGrayedOut: boolean
  isCrew?: boolean
  event: EventDB
  judgment: PrelimsJudgment
}>()
const { getAvatar } = useImage()

const roundsForJudgment = ref({
  0: props.judgment,
})

const judgment = ref(props.judgment)

const rounds = ref(
  Object.keys(roundsForJudgment.value).map((key) => ({
    round: Number(key),
    name: 'Round',
    judment: roundsForJudgment.value[key],
  }))
)

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'gone', id: number): void
  (e: 'edit'): void
  (e: 'update:prelims', value: unknown): void
}>()

const isEditMode = ref(false)
const isShowActions = ref(false)
const availableMainPoints = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const isPointDetailsOpened = ref(false)
const loading = ref(false)
const isShowDoneButton = ref(false)

async function updatePoints() {
  loading.value = true
  const updatedPrelims = await api.prelims.setJudgment(props.battleId, [judgment.value])

  loading.value = false
  if (!updatedPrelims) return
  emit('update:prelims', updatedPrelims)
}

function toggleEditMode() {
  if (isEditMode.value) {
    isEditMode.value = false
    isShowActions.value = false
    isShowDoneButton.value = false
    emit('cancel')
    updatePoints()
  } else {
    isEditMode.value = true
    isShowActions.value = true
    isShowDoneButton.value = true
    emit('edit')
  }
}

const isEditingPoints = computed(() => !!props.judgment.points)

function selectMainPoint(point: number, index: number) {
  const prevPoint = rounds.value[index].judment.points || 0
  const diff = point - Math.floor(prevPoint)
  rounds.value[index].judment.points += diff || -point
  if (isEditingPoints.value) return
  updatePoints()
}

function selectHalfPoint() {
  if (Number.isSafeInteger(rounds.value[0].judment.points)) {
    rounds.value[0].judment.points += 0.5
  } else {
    rounds.value[0].judment.points -= 0.5
  }
  if (isEditingPoints.value) return
  updatePoints()
}

onMounted(() => {
  if (props.judgment && !props.judgment.points && props.canEdit) {
    isEditMode.value = true
    emit('edit')
  }
})
</script>

<template>
  <article :class="['judging-row', { highlighted: isHighlighted, 'is-edit-mode': isEditMode }]">
    <div class="name-menu-wrap">
      <p
        v-if="isCrew"
        class="info-battle"
      >
        <span
          v-if="participant.randId"
          class="user-order"
        >
          {{ participant.randId }}.
        </span>
        {{ participant.name }}
      </p>

      <router-link
        v-else
        :to="{ name: 'Profile', params: { id: participant.id } }"
        class="user-link"
      >
        <span
          v-if="participant.randId"
          class="user-order"
        >
          {{ participant.randId }}.
        </span>
        <span class="participant-name">{{ participant.dancerName }}</span>
      </router-link>
      <div
        v-if="$route.name === 'Group'"
        class="btn-menu-container"
      >
        <v-btn
          v-if="event.permissions.canEdit"
          icon
          type="button"
          @click="isShowActions = !isShowActions"
        >
          <img
            v-if="!isShowActions"
            alt="open"
            src="/img/ic_Menu-Elipses-Horizontal.svg"
          />
          <img
            v-else
            alt="close"
            src="/img/service-worker.svg"
          />
        </v-btn>
      </div>
    </div>

    <div class="actions-container">
      <slot
        :is-show-actions="isShowActions"
        name="actions"
      />
    </div>
    <template v-if="isEditMode">
      <section class="points">
        <JudgingPoint
          v-for="point in availableMainPoints"
          :key="point"
          :active="Math.floor(rounds[0].judment.points) === point"
          :number="point"
          @click="selectMainPoint(point, 0)"
        />

        <JudgingPoint
          :active="!Number.isSafeInteger(rounds[0].judment.points) && !!rounds[0].judment.points"
          :icon="mdiStarOutline"
          @click="selectHalfPoint"
        />
      </section>
    </template>

    <template v-else>
      <div class="average-box">
        <div class="average-content">
          <span class="average-text">{{ participant.pointsAverage || '-' }}</span>
          <v-icon
            v-if="participant.prelims.rounds[0].length"
            class="chevron-icon"
            size="30"
            @click="isPointDetailsOpened = !isPointDetailsOpened"
          >
            {{ isPointDetailsOpened ? mdiChevronUp : mdiChevronDown }}
          </v-icon>
        </div>

        <v-checkbox
          v-if="canSetGone"
          v-model="participant.isGone"
          color="var(--color-blue-800)"
          hide-details="auto"
          @change="$emit('gone', participant.id)"
        />
      </div>

      <section
        v-if="isPointDetailsOpened"
        class="points-details"
      >
        <div
          v-for="judge in participant.prelims.rounds[0]"
          :key="judge.id"
          class="judge"
        >
          <span class="points-value">{{ judge.points }}</span>
          <div class="judge-info">
            <v-avatar size="32">
              <v-img :src="getAvatar(judge.avatar, 50)" />
            </v-avatar>
            <span class="name">{{ judge.dancerName }}</span>
          </div>
        </div>

        <BKButton
          v-if="canEdit"
          :loading="loading"
          class="edit-btn"
          color="var(--color-blue-800)"
          white-text
          @click="toggleEditMode"
        >
          Edit points
        </BKButton>
      </section>
    </template>

    <section
      v-if="isEditMode && isShowDoneButton"
      class="actions"
    >
      <BKButton
        v-if="canEdit"
        :loading="loading"
        class="done-btn"
        color="var(--color-blue-800)"
        outlined
        @click="toggleEditMode"
      >
        Done
      </BKButton>
    </section>
  </article>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.judging-row {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 20px;
  background-color: inherit;

  &.is-edit-mode {
    background-color: transparent !important;
  }

  .info-battle {
    margin-bottom: 0;

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .name-menu-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-menu-container {
      @media (max-width: 768px) {
        padding: 0 20px;
      }
    }
  }

  &.highlighted {
    border: 2px solid var(--color-orange-500);
  }

  @media (max-width: 768px) {
    padding: 40px 0;
  }
}

.actions-container {
  padding: 0 20px;
}

.user-link {
  display: flex;
  align-items: center;
  width: max-content;
  color: var(--color-dark-800) !important;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.points {
  display: flex;
  overflow-x: scroll;
  column-gap: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.average-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .average-content {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  .average-text {
    color: var(--color-grey-300);
    font-weight: var(--font-weight-bold);
    font-size: 44px;
    line-height: 50px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.points-details {
  display: grid;
  row-gap: 16px;

  .points-value {
    color: var(--color-grey-300);
    font-size: 44px;
    line-height: 50px;
  }

  .judge {
    display: grid;
    grid-template-columns: 86px 1fr;
    gap: 18px;
    align-items: center;

    .name {
      color: var(--color-grey-300);
    }

    .judge-info {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.actions {
  padding: 0 20px;

  .done-btn {
    margin-top: 20px;
  }
}
</style>
