<script lang="ts" setup>
import JudgingPoint from '@/components/Battle/Prelims/JudgingPoint.vue'
import { IParticipant, PrelimsJudgment } from '@/types/battle'
import { computed, onMounted, ref, watchEffect } from 'vue'
import { mdiChevronDown, mdiChevronUp, mdiClose, mdiStarOutline } from '@mdi/js'
import { EventDB } from '@/enitites/event'
import { useImage } from '@/shared/composables/image'
import api from '@/api'
import { BattleDB } from '@/enitites/battle'
import store from '@/store'

const props = defineProps<{
  participant: IParticipant
  battle: BattleDB
  canEdit: boolean
  canSetGone: boolean
  isHighlighted: boolean
  isGrayedOut: boolean
  isCrew?: boolean
  event: EventDB
  judgment: PrelimsJudgment
}>()

const { getAvatar } = useImage()
const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'gone', id: number): void
  (e: 'edit'): void
  (e: 'update:prelims', value: unknown): void
}>()
const roundsForJudgment = ref(
  Array.from({ length: props.battle.crewSize }, (_, index) => ({
    ...props.judgment,
    round: index + 1,
  }))
)
const getUserDB = computed(() => store.getters.getUserDB)
const rounds = ref(
  roundsForJudgment.value.map((judgment) => ({
    round: judgment.round,
    name: 'Round',
    judgment: {
      ...judgment,
      points:
        props.participant.prelims.rounds[judgment.round]?.find((judge) => judge.id === getUserDB.value.id)?.points || 0,
    },
  }))
)

const currentRound = ref(0)

const isEditMode = ref(false)
const isShowActions = ref(false)
const availableMainPoints = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const isPointDetailsOpened = ref(false)
const loading = ref(false)
const isShowDoneButton = ref(false)

async function updatePoints() {
  loading.value = true
  const judgments = rounds.value.map((round) => round.judgment)
  const updatedPrelims = await api.prelims.setJudgment(props.battle.id, judgments)

  loading.value = false
  if (!updatedPrelims) return
  emit('update:prelims', updatedPrelims)
}

function toggleEditMode() {
  if (isEditMode.value) {
    isEditMode.value = false
    isShowActions.value = false
    isShowDoneButton.value = false
    emit('cancel')
    updatePoints()
  } else {
    isEditMode.value = true
    isShowActions.value = true
    isShowDoneButton.value = true
    emit('edit')
  }
}

const roundsData = computed(() => {
  const judgesMap = new Map()

  Object.entries(props.participant.prelims.rounds).forEach(([round, judges]) => {
    judges.forEach((judge) => {
      if (!judgesMap.has(judge.id)) {
        judgesMap.set(judge.id, { ...judge, pointsPerRound: {} })
      }
      judgesMap.get(judge.id).pointsPerRound[round] = judge.points
    })
  })

  return Array.from(judgesMap.values())
})
const changeTabTimeout = ref<ReturnType<typeof setTimeout> | null>(null)
const isAnyRoundWithoutPoints = computed(() => roundsForJudgment.value.some((round) => !round.points))

function startTimeOutForChangingTab() {
  if (changeTabTimeout.value) {
    clearTimeout(changeTabTimeout.value)
  }

  changeTabTimeout.value = setTimeout(() => {
    if (rounds.value.length - 1 > currentRound.value) {
      currentRound.value += 1
    }
  }, 10000)
}

function selectMainPoint(point: number, index: number) {
  const prevPoint = rounds.value[index].judgment.points || 0
  const diff = point - Math.floor(prevPoint)
  rounds.value[index].judgment.points += diff || -point
  if (!isAnyRoundWithoutPoints.value) return
  updatePoints()
  startTimeOutForChangingTab()
}

function selectHalfPoint() {
  if (Number.isSafeInteger(rounds.value[currentRound.value].judgment.points)) {
    rounds.value[currentRound.value].judgment.points += 0.5
  } else {
    rounds.value[currentRound.value].judgment.points -= 0.5
  }
  if (!isAnyRoundWithoutPoints.value) return
  updatePoints()
  startTimeOutForChangingTab()
}

const judgeRefs = ref<HTMLElement[]>([])
const isJudgeElScrollable = ref(false)
const isShowFullListOfPoints = ref<boolean[]>(roundsData.value.map(() => false))

function toggleFullList(index: number) {
  isShowFullListOfPoints.value[index] = !isShowFullListOfPoints.value[index]
  isShowFullListOfPoints.value = [...isShowFullListOfPoints.value]
}

watchEffect(() => {
  isJudgeElScrollable.value = judgeRefs.value.some((el) => el.scrollWidth > el.clientWidth)
})
onMounted(() => {
  if (props.judgment && props.canEdit && !props.judgment.points) {
    isEditMode.value = true
    emit('edit')
  }
})
</script>

<template>
  <article :class="['judging-row', { highlighted: isHighlighted, 'is-edit-mode': isEditMode }]">
    <div class="name-menu-wrap">
      <p
        v-if="isCrew"
        class="info-battle"
      >
        <span
          v-if="participant.randId"
          class="user-order"
        >
          {{ participant.randId }}.
        </span>
        {{ participant.name }}
      </p>
      <div
        v-if="$route.name === 'Group'"
        class="btn-menu-container"
      >
        <v-btn
          v-if="event.permissions.canEdit"
          icon
          type="button"
          @click="isShowActions = !isShowActions"
        >
          <img
            v-if="!isShowActions"
            alt="open"
            src="/img/ic_Menu-Elipses-Horizontal.svg"
          />
          <img
            v-else
            alt="close"
            src="/img/service-worker.svg"
          />
        </v-btn>
      </div>
    </div>
    <div class="actions-container">
      <slot
        :is-show-actions="isShowActions"
        name="actions"
      />
    </div>
    <template v-if="isEditMode">
      <div class="tabs-container">
        <v-tabs-items
          v-model="currentRound"
          class="content-tabs"
          touchless
        >
          <v-tab-item
            v-for="(item, index) in rounds"
            :key="item.round"
          >
            <section class="points">
              <JudgingPoint
                v-for="point in availableMainPoints"
                :key="point"
                :active="Math.floor(item.judgment.points) === point"
                :number="point"
                @click="selectMainPoint(point, index)"
              />

              <JudgingPoint
                :active="!Number.isSafeInteger(item.judgment.points) && !!item.judgment.points"
                :icon="mdiStarOutline"
                @click="selectHalfPoint"
              />
            </section>
          </v-tab-item>
        </v-tabs-items>
        <v-tabs
          v-model="currentRound"
          background-color="transparent"
          class="round-tabs"
          color="basil"
          height="16"
          slider-color="var(--color-grey-500)"
          slider-size="1"
        >
          <v-tab
            v-for="(item, index) in rounds"
            :key="item.round"
            active-class="active-tab"
            class="round-tab"
          >
            {{ currentRound === index ? `${item.name} ${item.round}` : `R${item.round}` }}
            {{ rounds[index].judgment.points && currentRound !== index ? `:${rounds[index].judgment.points}` : '' }}
          </v-tab>
        </v-tabs>
      </div>
    </template>
    <template v-else>
      <div class="average-box">
        <div class="average-content">
          <span class="average-text">{{ participant.pointsAverage || '-' }}</span>
          <v-icon
            v-if="participant.prelims.rounds[1].length"
            class="chevron-icon"
            size="30"
            @click="isPointDetailsOpened = !isPointDetailsOpened"
          >
            {{ isPointDetailsOpened ? mdiChevronUp : mdiChevronDown }}
          </v-icon>
        </div>

        <v-checkbox
          v-if="canSetGone"
          v-model="participant.isGone"
          color="var(--color-blue-800)"
          hide-details="auto"
          @change="emit('gone', participant.id)"
        />
      </div>

      <section
        v-if="isPointDetailsOpened"
        class="points-details"
      >
        <div
          v-for="(judge, index) in roundsData"
          :key="judge.id"
          ref="judgeRefs"
          class="judge"
        >
          <div class="judge-info">
            <span class="average-text average-by-round">{{ judge.averageByRound }}</span>
            <v-avatar size="32">
              <v-img :src="getAvatar(judge.avatar, 50)" />
            </v-avatar>
            <span class="name">{{ judge.dancerName }}</span>
          </div>
          <button
            :class="[
              'round-points',
              { 'is-scrollable': isJudgeElScrollable, 'is-show-full-list': isShowFullListOfPoints[index] },
            ]"
            type="button"
            @click="isJudgeElScrollable ? toggleFullList(index) : null"
          >
            <v-icon class="icon">
              {{ mdiClose }}
            </v-icon>
            <span
              v-for="(points, round) in judge.pointsPerRound"
              :key="round"
            >
              <span class="round">
                R {{ round }}: <span class="value">{{ points.toFixed(1) }}</span>
              </span>
            </span>
          </button>
        </div>

        <BKButton
          v-if="canEdit"
          :loading="loading"
          class="edit-btn"
          color="var(--color-blue-800)"
          white-text
          @click="toggleEditMode"
        >
          Edit points
        </BKButton>
      </section>
    </template>

    <section
      v-if="isEditMode && isShowDoneButton"
      class="actions"
    >
      <BKButton
        v-if="canEdit"
        :loading="loading"
        class="done-btn"
        color="var(--color-blue-800)"
        outlined
        @click="toggleEditMode"
      >
        Done
      </BKButton>
    </section>
  </article>
</template>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.judging-row {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 20px;
  background-color: inherit;

  &.is-edit-mode {
    background-color: transparent !important;
  }

  .info-battle {
    margin-bottom: 0;

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .name-menu-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-menu-container {
      @media (max-width: 768px) {
        padding: 0 20px;
      }
    }
  }

  &.highlighted {
    border: 2px solid var(--color-orange-500);
  }

  @media (max-width: 768px) {
    padding: 40px 0;
  }
}

.actions-container {
  padding: 0 20px;
}

.user-link {
  display: flex;
  align-items: center;
  width: max-content;
  color: var(--color-dark-800) !important;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.points {
  display: flex;
  overflow-x: scroll;
  column-gap: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.average-text {
  color: var(--color-grey-300);
  font-weight: var(--font-weight-bold);
  font-size: 44px;
  line-height: 50px;
}

.average-by-round {
  margin-right: 4px;
  font-weight: initial;
}

.average-box {
  .average-content {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.points-details {
  display: grid;
  row-gap: 16px;

  .points-value {
    color: var(--color-grey-300);
    font-size: 44px;
    line-height: 50px;
  }

  .judge {
    position: relative;
    display: grid;
    grid-template-columns: 164px 1fr;
    overflow: scroll;
    column-gap: 106px;

    &::-webkit-scrollbar {
      display: none;
    }

    .round-points {
      left: 0;
      display: flex;
      gap: 30px;
      align-items: center;
      justify-self: flex-end;
      color: var(--color-grey-500);
      transition:
        left 0.3s,
        opacity 0.3s,
        transform 1s;

      .round {
        white-space: nowrap;
      }

      @include font-h2;

      .value {
        color: var(--color-grey-300);
      }
    }

    .round-points.is-scrollable {
      position: absolute;
      left: 60%;
      z-index: 5;
      height: 100%;
      opacity: 0.1;
    }

    .icon {
      width: 0;
      height: 0;
      visibility: hidden;
      opacity: 0;
    }

    .round-points.is-show-full-list {
      left: 0;
      z-index: 2;
      justify-self: initial;
      overflow: scroll;
      background-color: var(--color-grey-100);
      opacity: 1;
      padding-inline: 20px;

      .icon {
        width: 24px;
        height: 24px;
        visibility: visible;
        opacity: 1;
      }
    }

    .name {
      color: var(--color-grey-300);
      white-space: nowrap;
    }

    .judge-info {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.actions {
  padding: 0 20px;

  .done-btn {
    margin-top: 20px;
  }
}

.tabs-container {
  .content-tabs {
    margin-bottom: 24px;
    background-color: transparent;

    .points {
      @media (max-width: 768px) {
        padding: 0 20px;
      }
    }
  }

  .round-tabs {
    :deep(.v-tabs-bar__content) {
      gap: 20px;
    }

    .round-tab {
      justify-content: flex-start;
      min-width: 20px;
      padding: 0;
      color: var(--color-grey-600) !important;
      font-size: 12px;
      text-transform: none;

      &.active-tab {
        color: var(--color-grey-500) !important;
      }
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }
}
</style>
