<template>
  <div
    v-if="prelims"
    class="prelims-participants-crews"
  >
    <div class="dancers-heading">
      <slot name="title" />

      <BKSort
        :sort-options="sortOptions"
        class="sort"
        @update="updateSort"
      />
    </div>

    <div class="search-wrapper">
      <BKInput
        v-model="searchText"
        :append-icon="mdiMagnify"
        class="search"
        label="Search dancers"
        @input="handleSearch"
      />
    </div>
    <div
      v-for="crew in filteredParticipants"
      :key="crew.id + crew.passStatus + finishKey"
      :class="['score', { highlighted: getIsCrewHighlighted(crew) }, getCssStatusClass(crew)]"
    >
      <p
        v-if="crew.passStatus === 'sameScore' && !!crew.pointsAverage"
        ref="error"
        class="same-score-error-msg"
      >
        Too many competitors with the same points for the final bracket spot(s). Update the points on the competitors to
        proceed.
      </p>
      <PrelimsRoundScore
        :battle="battle"
        :can-edit="prelims.permissions.canJudge"
        :can-set-gone="prelims.permissions.canSetGone"
        :event="event"
        :is-grayed-out="getIsCrewGrayedOut(crew)"
        :is-highlighted="getIsCrewHighlighted(crew)"
        :judgment="getCrewJudgment(crew.id)"
        :participant="crew"
        is-crew
        @cancel="cancelEditCrewJudgment(crew.id)"
        @edit="setCrewForEditJudgment(crew.id)"
        @gone="setGone"
        @update:prelims="updatePrelims"
      >
        <template #actions="{ isShowActions }">
          <slot
            :crew-id="crew.id"
            :is-show-actions="isShowActions"
            name="actions"
          />
        </template>
      </PrelimsRoundScore>
    </div>

    <section class="actions col">
      <BKButton
        v-if="prelims.permissions.canFinishPrelims"
        :disabled="!prelims.permissions.canShuffle"
        :loading="loadingShuffleBtn"
        color="var(--color-blue-800)"
        white-text
        @click="reshuffle"
      >
        Randomize
      </BKButton>
      <template v-if="prelims.permissions.canFinishPrelims && $route.name !== 'Group'">
        <BKButton
          ref="addBracketBtn"
          :disabled="prelims.permissions.canShuffle || !!prelims.permissions.error"
          :loading="loading"
          color="var(--color-blue-800)"
          white-text
          @click="showAddToBracketsConfirmationDialog"
        >
          Add to bracket
        </BKButton>

        <span
          v-if="prelims.permissions.error"
          class="submit-text err"
        >
          {{ prelims.permissions.error }}
        </span>
      </template>
      <BKButton
        v-if="prelims.permissions.canUndoBrackets"
        ref="addBracketBtn"
        :loading="loading"
        white-text
        @click="showUndoBracketsConfirmationDialog"
      >
        Undo bracket
      </BKButton>
    </section>
    <BKConfirmationDialog
      :confirm-text="confirmationDialog.confirmText"
      :is-show="confirmationDialog.isShow"
      :title="confirmationDialog.title"
      @cancel="confirmationDialog.isShow = false"
      @confirm="confirmationDialog.confirm"
    >
      {{ confirmationDialog.subtitle }}
    </BKConfirmationDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '@/api'

import BKInput from '@/components/AppShared/BKInput.vue'
import { mdiMagnify } from '@mdi/js'
import { BKConfirmationDialog } from '@/shared/ui/modals'
import PrelimsRoundScore from '@/components/Battle/Prelims/PrelimsRoundScoreCrews.vue'

export default {
  name: 'PrelimsCrews',
  components: {
    PrelimsRoundScore,
    BKConfirmationDialog,
    BKInput,
  },
  props: {
    battle: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    groupId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      mdiMagnify,
      filteredCrews: [],
      loading: false,
      prelims: null,
      crewsForJudgment: [],
      filteredParticipants: [],
      loadingShuffleBtn: false,
      sortOptions: [
        { name: 'Points', value: 'points' },
        { name: 'Name', value: 'name' },
        { name: 'Assigned number', value: 'number' },
      ],
      sortBy: '',
      searchText: '',
      finishKey: 0,
      confirmationDialog: {
        isShow: false,
        title: '',
        subtitle: '',
        confirmText: '',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        confirm: () => {},
      },
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    crewsRegisteredCount() {
      return this.prelims.crews.length
    },
    isParticipantWithSameScore() {
      if (!this.prelims) return false
      return this.prelims.crews.some((c) => c.passStatus === 'sameScore')
    },
    currentUserCrewId() {
      if (!this.getUserDB) return 0
      const currentUserCrew = this.battle.crews.find((cr) =>
        cr.participants.find((par) => par.id === this.getUserDB.id && par.confirmed)
      )
      if (!currentUserCrew) return 0

      return currentUserCrew.id
    },
    isParticipantsWithStatus() {
      return this.prelims.crews.every((p) => p.passStatus)
    },
    isParticipantsWithoutScore() {
      return this.prelims.crews.some((p) => p.pointsAverage === 0)
    },
  },
  watch: {
    prelims: {
      handler() {
        this.handleSearch()
      },
      deep: true,
    },
    groupId() {
      this.handleSearch()
    },
  },
  async mounted() {
    await this.fetchPrelims()
    if (this.prelims.permissions.canJudge) this.setCrewsForJudgment()
    if (this.isParticipantWithSameScore) this.scrollToErrorMsg()
  },
  methods: {
    ...mapActions(['setIsNotFound', 'showAlertToast']),
    getCssStatusClass(crew) {
      if (!crew) return ''
      const isCurrentUserAdmin = !!this.event.staff.admins.find((admin) => admin.id === this.getUserDB?.id)
      const isCurrentUserOrganizer = this.event.staff?.organizer?.id === this.getUserDB?.id
      if (this.getCrewJudgment(crew.id)?.points || crew.isPromoted || isCurrentUserAdmin || isCurrentUserOrganizer) {
        return {
          'is-passed': crew.passStatus === 'passed',
          'is-samescore': crew.passStatus === 'sameScore',
        }
      }
      return ''
    },
    showUndoBracketsConfirmationDialog() {
      this.confirmationDialog = {
        isShow: true,
        title: 'Undo brackets',
        subtitle: `Are you sure you want to undo brackets?`,
        confirmText: 'Undo',
        confirm: () => {
          this.undoBrackets()
          this.confirmationDialog.isShow = false
        },
      }
    },
    showAddToBracketsConfirmationDialog() {
      this.confirmationDialog = {
        isShow: true,
        title: 'Add to bracket',
        subtitle: `Are you sure you want to add to brackets?`,
        confirmText: 'Add',
        confirm: () => {
          this.finishPrelims()
          this.confirmationDialog.isShow = false
        },
      }
    },
    updateSort(sortBy) {
      this.sortBy = sortBy
      this.fetchPrelims()
    },
    updatePrelims(prelims) {
      this.prelims = prelims
      if (!this.isParticipantWithSameScore && this.isParticipantsWithStatus && !this.isParticipantsWithoutScore) {
        this.scrollToAddBracketBtn()
        this.showAlertToast('bracketingIsReady')
      } else {
        this.scrollToErrorMsg()
      }
    },
    async fetchPrelims() {
      const prelims = await api.prelims.getPrelims(this.$route.params.id, this.sortBy)
      if (!prelims) {
        this.setIsNotFound(true)
        return
      }

      this.prelims = prelims
    },
    async reshuffle() {
      this.loadingShuffleBtn = true
      const updatedPrelims = await api.prelims.reshuffleParticipants(this.battle.id)
      this.loadingShuffleBtn = false
      if (!updatedPrelims) return
      this.prelims = updatedPrelims
    },
    getIsCrewHighlighted(crew) {
      return crew.id === this.currentUserCrewId
    },
    getIsCrewGrayedOut(crew) {
      if (this.battle.status === 'prelims-finished') return !crew.isPromoted
      return crew.isPromoted || crew.isGone
    },
    getCrewJudgment(crewId) {
      const crew = this.prelims.crews.find((p) => p.id === crewId)
      if (!crew || !this.getUserDB) return null

      const currentUserJudgment = crew.prelims.rounds[1].find((j) => j.id === this.getUserDB.id)

      return {
        crewId: crew.id,
        points: currentUserJudgment ? currentUserJudgment.points : null,
        mode: 'edit',
        round: 1,
      }
    },
    setCrewForEditJudgment(crewId) {
      const crew = this.prelims.crews.find((p) => p.id === crewId)
      if (!crew) return

      if (!this.getUserDB) return
      const currentUserJudgment = crew.prelims.rounds[1].find((j) => j.id === this.getUserDB.id)
      if (!currentUserJudgment) return

      const crewForEdit = {
        crewId: crew.id,
        points: currentUserJudgment.points,
        mode: 'edit',
        round: 1,
      }

      this.crewsForJudgment.push(crewForEdit)
    },
    cancelEditCrewJudgment(crewId) {
      this.crewsForJudgment = this.crewsForJudgment.filter((p) => p.crewId !== crewId)
    },
    setCrewsForJudgment() {
      this.crewsForJudgment = []
      if (!this.getUserDB) return
      this.prelims.crews.forEach((cr, index) => {
        if (cr.prelims.rounds[1].find((j) => j.id === this.getUserDB.id && j.points)) return

        this.crewsForJudgment.push({
          crewId: cr.id,
          points: 0,
          mode: 'new',
          round: index,
        })
      })
    },
    async finishPrelims() {
      this.loading = true

      const updatedPrelims = await api.prelims.finishPrelims(this.battle.id)
      this.loading = false
      if (!updatedPrelims) return
      this.finishKey += 1
      this.prelims = updatedPrelims
      if (this.isParticipantWithSameScore) this.scrollToErrorMsg()
    },
    async undoBrackets() {
      this.loading = true
      await api.prelims.undoBrackets(this.battle.id)
      await this.fetchPrelims()
      this.loading = false
      this.finishKey += 1
    },
    async setGone(crewId) {
      const updatedPrelims = await api.prelims.setGone(this.battle.id, crewId)
      if (!updatedPrelims) return
      this.prelims = updatedPrelims
    },
    handleSearch() {
      if (this.groupId) {
        this.filteredParticipants = this.prelims.crews.filter((p) => {
          const name = p.name.toLowerCase()
          const search = this.searchText.toLowerCase()
          return name.includes(search) && p.group.toLowerCase() === this.groupId.toLowerCase()
        })
        return
      }
      this.filteredParticipants = this.prelims.crews.filter((p) => {
        const name = p.name.toLowerCase()
        const search = this.searchText.toLowerCase()
        return name.includes(search)
      })
    },
    scrollToErrorMsg() {
      this.$nextTick(() => {
        if (!this.$refs.error) return
        if (Array.isArray(this.$refs.error)) {
          const showingErrorMsg = this.$refs.error.find((item) => item.clientHeight)
          if (showingErrorMsg) {
            showingErrorMsg.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }
      })
    },
    scrollToAddBracketBtn() {
      this.$nextTick(() => {
        if (!this.$refs.addBracketBtn) return
        this.$refs.addBracketBtn.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.prelims-participants-crews {
  background-color: var(--color-grey-100) !important;

  .same-score-error-msg {
    display: none;
  }

  .score {
    border-bottom: 1px solid var(--color-grey-600);

    &.highlighted {
      border-bottom: 2px solid var(--color-orange-500);
    }

    &.is-passed {
      ::v-deep {
        .judging-row {
          background-color: #83eaba;
          border-color: #00bf70;
        }

        .average-text,
        .chevron-icon,
        .points-value {
          color: #00824c;
        }

        .round-points {
          color: #00824c;
          background-color: #83eaba;

          .value {
            color: #00824c;
          }

          .icon {
            color: #00824c;
          }
        }
      }
    }

    &.is-samescore {
      ::v-deep {
        .average-text,
        .chevron-icon,
        .points-value {
          color: var(--rasberry-red);
        }
      }
    }

    &.is-samescore .same-score-error-msg {
      margin-bottom: 8px;
      padding: 40px 20px 0;
      color: var(--rasberry-red);
    }
  }

  & :not(.is-samescore) + .is-samescore .same-score-error-msg {
    display: block;
  }
}

.dancers-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;

  .count {
    @include font-h3;
  }

  .sort {
    margin-left: auto;
  }
}

.search-wrapper {
  margin-bottom: 20px;
  padding: 0 20px;
}

.submit-text {
  display: block;
  color: var(--color-grey-300);

  &.err {
    color: #ff2d53;
  }
}

.actions {
  display: grid;
  margin: 40px 0 0;
  padding: 0 20px;
  row-gap: 20px;

  &:empty {
    display: none;
  }
}
</style>
